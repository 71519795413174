<template>
  <div class="disponseInfo">
    <div>
      <span>处方信息</span>
      <hr />
    </div>
    <!-- 非图片处方 -->
    <template v-if="preInfo && preInfo.type == 0">
      <div class="bill_wrap">
        <div class="bill">
          <div class="date">{{ preInfo.add_time.split(" ")[0] }}</div>
          <div class="invald">本处方单3个月内有效</div>
          <h3>处方笺</h3>
          <el-row>
            <el-col :span="12">
              科别:
              <span>{{ preInfo.inquiry_info.category_name }}</span>
            </el-col>
            <el-col :span="12">
              处方号:
              <span>{{ preInfo.inquiry_info.p_code }}</span>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              姓名:
              <span>{{ preInfo.inquiry_info.realname }}</span>
            </el-col>
            <el-col :span="8">
              年龄:
              <span>{{ preInfo.inquiry_info.age }}</span>
            </el-col>
            <el-col :span="8">
              性别:
              <span>{{ preInfo.inquiry_info.sex === 0 ? "保密" : (preInfo.inquiry_info.sex === 1 ? "男" : "女") }}</span>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              过敏史:
              <span>{{ preInfo.inquiry_info.allergy }}</span>
            </el-col>
            <el-col :span="12">
              遗传史:
              <span>{{ preInfo.inquiry_info.genetic_disease }}</span>
            </el-col>
          </el-row>
          <el-row>
            临床诊断:
            <span>{{ preInfo.inquiry_info.diagnosis }}</span>
          </el-row>
          <div class="content">
            <div class="type">R</div>
            <div class="name" v-for="(item, i) in preInfo.goods_list" :key="i">
           <el-row>
             <el-col :span="8">{{ item.goods_name }}</el-col>
             <el-col :span="8">{{item.spec}}</el-col>
             <el-col :span="8">x{{item.num}} {{item.unit}}</el-col>
             <el-col :span="8">用法</el-col>
             <el-col :span="8">{{ item.drugRoute_name}}</el-col>
             <el-col :span="8">每次{{item.drugDosage}}{{item.drugUseUnit_name}}</el-col>
           </el-row>
            </div>
          </div>

          <div class="graph">
            <el-row>
              <el-col :span="12">
                <p>医生签字:</p>
                <img :src="doctorSign.audit_sign" alt />
              </el-col>
              <el-col :span="12">
                <p>药师签字:</p>
                <img :src="prarmSign.audit_sign" alt />
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </template>
    <!-- 图片处方 -->
    <template v-else-if="preInfo && preInfo.type == 1">
      <div class="wrapper">
        <div class="pre_img">
          <img :src="preInfo.pic" alt />
        </div>
        <div class="drug_wrap">
          <div class="title">
            <span class="pre_title">药师选药</span>
            <hr />
          </div>
          <div class="drugInfo">
            <div>
              <span>药品信息</span>
            </div>
            <div>
              <span>数量</span>
            </div>
          </div>
          <div class="content" v-for="(good, i) in preInfo.goods_list" :key="i">
            <div class="info">
              <dl>
                <dd>
                  <img :src="good.goods_img" alt />
                  <p>{{ good.goods_name }}</p>
                </dd>
                <dd>
                  <span>用法</span>
                  <span>{{ good.yongfa }}</span>
                </dd>
                <dd>
                  <span>用量</span>
                  <span>{{ good.yongliang }}</span>
                </dd>
              </dl>
            </div>
            <div class="num">
              <span>x {{ good.num }}</span>
            </div>
          </div>
        </div>
      </div>
    </template>
    <div class="wrap_autograph">
      <span>调配签名</span>
      <hr />
      <div v-if="noAutograph" class="autograph">
        <span @click="isShow = true">点击签名</span>
      </div>
      <div v-if="already">
        <img :src="imgUrl" @click="clearAutograph" alt />
      </div>
      <div id="align">
        <el-button type="info" @click="save()">保存</el-button>
      </div>
    </div>
    <div @mousewheel.prevent class="mask" v-if="isShow">
      <div class="autograph">
        <div class="sel">
          <span>调配签名</span>
          <img src="@/assets/image/Interrogation_Drugs_delete@2x.png" @click="isShow = false" />
        </div>
        <div class="content">
          <Autograph @getImgUrl="getImgUrl" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Autograph from "@/components/autograph";

// 获取处方单详情
import { getInfo } from "@/request/personnel";
// 审核处方单
import { audit } from "@/request/personnel";

export default {
  props: ["pCode"],
  components: {
    Autograph
  },
  data() {
    return {
      isShow: false,
      imgUrl: "",
      noAutograph: true, // 已签名状态
      already: false, // 未签名状态
      preInfo: "", // 处方单详情
      doctorSign: {}, // 医生签字对象
      prarmSign: {} // 药师签字对象
    };
  },
  created() {
    getInfo({
      token: this.$token,
      p_code: this.pCode
    }).then(res => {
      this.preInfo = res.data;
      this.preInfo.sign_list.forEach(item => {
        if (item.cert_type == 1 || item.cert_type == 2) {
          this.doctorSign = item;
        } else if (item.cert_type == 3) {
          this.prarmSign = item;
        }
      });
      console.log(this.preInfo)

      // console.log(res);
    });
  },
  methods: {
    getImgUrl(imgUrl) {
      this.imgUrl = imgUrl;
      this.noAutograph = false;
      this.already = true;
      this.isShow = false;
    },
    clearAutograph() {
      this.isShow = true;
    },
    save() {
      // 发送处方单
      if (this.imgUrl == "") {
        this.$message({
          type: "error",
          message: "请签名后在提交"
        });
      }
      audit({
        token: this.$token,
        p_code: this.preInfo.p_code,
        audit_status: 1,
        audit_sign: this.imgUrl
      }).then(res => {
        if (res.code == 1) {
          this.$message({
            type: "success",
            message: "保存成功"
          });
          this.$emit("changeState", 0);
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.disponseInfo {
  width: 1200px;
  color: #333;
  margin: 0 auto;
}
.disponseInfo > div {
  padding: 30px;
}
// 单据信息
.disponseInfo .bill_wrap {
  background: #fbfbfb;
}
// 单据信息
.disponseInfo .bill {
  box-sizing: border-box;
  background: #fff;
  margin-left: 200px;
  box-shadow: 5px 5px 5px #ccc;
  width: 600px;
  min-height: 744px;
  font-size: 16px;
  border: 1px solid #ccc;
  padding: 20px;
  .date {
    margin-bottom: 5px;
  }
  h3 {
    font-size: 28px;
    font-weight: 500;
    text-align: center;
    margin: 10px 0px;
  }
  > .el-row {
    border-bottom: 1px solid #424242;
    line-height: 40px;
  }
  .content {
    min-height: 100px;
    padding: 10px 0px 15px 0px;
    .el-row {
      border: none;
      padding: 3px 0;
      .el-col {
        margin: 3px 0;
      }
      .el-col:nth-child(2) {
        text-align: center;
      }
      .el-col:nth-child(4) {
        text-indent: 2em;
      }
      .el-col:nth-child(5) {
        text-align: center;
      }
      .el-col:nth-child(3) {
        text-align: right;
      }
      .el-col:nth-child(6) {
        text-align: right;
      }
      .el-col {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
    .type {
      font-size: 22px;
      font-weight: 700;
    }
    .name {
      margin: 3px 0;
    }
  }
  .graph {
    border-top: 1px solid #424242;
    height: 150px;
    .el-row {
      margin-top: 12px;
      img {
        width: 100%;
      }
    }
  }
}
.disponseInfo .autograph {
  height: 100px;
  line-height: 100px;
  text-align: center;
  font-size: 13px;
  color: #999;
  margin-left: -25px;
  cursor: pointer;
}
.disponseInfo div.wrap_autograph {
  width: 1200px;
  margin-top: 15px;
}
.disponseInfo div.wrap_autograph div {
  text-align: center;
}
.disponseInfo div.wrap_autograph div button:nth-child(2) {
  margin-left: 50px;
}
.el-button {
  width: 120px;
  height: 50px;
  background: rgba(56, 238, 193, 1);
  font-size: 22px;
}
.wrapper {
  /deep/ .el-dialog__footer {
    text-align: center;
  }
  /deep/ .el-dialog__body {
    padding: 10px 20px;
    .qianming {
      border: 1px solid #ccc;
      height: 200px;
      text-align: center;
    }
  }
  padding: 0px !important;
}
.mask {
  position: fixed;
  left: 0px;
  top: 0px;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 3;
  .autograph {
    width: 604px;
    height: 370px;
    padding: 12px;
    position: absolute;
    left: 0px;
    top: 0px;
    bottom: 0px;
    right: 0px;
    margin: auto;
    background: #fff;
    text-align: left;
    .sel {
      line-height: 20px;
      color: #424242;
    }
    div {
      img {
        width: 15px;
        height: 15px;
        float: right;
        cursor: pointer;
      }
    }
    .content {
      margin: 20px 20px 20px;
      height: 240px;
      border: 1px solid #ccc;
    }
  }
}
hr {
  border: none;
  width: 960px;
  height: 2px;
  display: inline-block;
  margin-left: 10px;
  margin-bottom: 5px;
  background-color: #f5f5f5 !important;
}
.disponseInfo,
.wrap_autograph {
  padding: 30px 0px !important;
  font-size: 22px;
  > span {
    margin-left: 30px;
  }
}
.wrap_autograph .autograph {
  height: 148px;
  background: #fbfbfb;
  margin: 20px 0 30px 0px;
  span {
    display: inline-block;
    margin-top: 30px;
  }
}
// 图片处方单的样式
.disponseInfo {
  .wrapper {
    background: #ffffff;
  }
  .pre_title {
    margin-left: 30px;
  }
  .pre_img {
    margin-top: 42px;
    margin-bottom: 44px;
    height: 822px;
    background: rgba(251, 251, 251, 1);
    overflow: hidden;
    img {
      width: 984px;
      height: 695px;
      display: block;
      margin: 59px auto 68px;
    }
  }
  .drug_wrap .title {
    margin-bottom: 25px;
  }
  .drug_wrap .drugInfo {
    width: 1140px;
    display: flex;
    height: 65px;
    background: #fbfbfb;
    line-height: 65px;
    margin-bottom: 16px;
    div:nth-of-type(1) {
      width: 900px;
      span {
        margin-left: 65px;
      }
    }
    div:nth-of-type(2) {
      width: 200px;
      span {
        margin-left: 100px;
      }
    }
  }
  .drug_wrap .content {
    height: 160px;
    background: #fbfbfb;
    margin-bottom: 16px;
    overflow: hidden;
    div:nth-of-type(1) {
      width: 850px;
      float: left;
      overflow: hidden;
      margin-left: 74px;
      img {
        width: 40px;
        height: 40px;
        float: left;
      }
      p {
        margin-left: 50px;
        width: 326px;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }
      dd:nth-of-type(1) {
        overflow: hidden;
      }
      dd {
        margin-top: 15px;
      }
      span {
        margin-left: 14px;
        font-size: 16px;
        color: #333;
      }
    }
    div:nth-of-type(2) {
      width: 200px;
      float: left;
      box-sizing: border-box;
    }
    .num {
      text-align: center;
      margin-top: 15px;
    }
  }
}
</style>