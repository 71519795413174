<template>
  <div class="expert">
    <Header />
    
    <LandHeader nameType="调配篇" @changeState="changeState" />
    <!-- 修改处方 -->
    <UpdatePre v-if="state === 0" @changeState="changeState" />
    <!-- 查看处方详情 -->
    <DisposeInfo 
      v-if="state === 1"
      :pCode="pCode" 
      @changeState="changeState"
    />
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/home_repect/header-top.vue";
import Footer from "@/components/public/public_bottom.vue";
import LandHeader from "@/components/home_repect/header.vue";
import UpdatePre from "./updatePre" // 修改处方
import DisposeInfo from "./disposeInfo" // 查看处方详情



export default {
  data() {
      return {
          state: 0,
          pCode: ""
      }
  },
  components: {
    Header,
    Footer,
    LandHeader,
    UpdatePre,
    DisposeInfo
  },
  methods: {
    changeState(num, pCode) {
      this.state = num;
      if(num == 1) {
        this.pCode = pCode;
      } 
    }
  }
};
</script>

<style scoped>
.banner img {
  width: 100%;
  height: 350px;
}
</style>