// 药师审方

<template>
  <div class="trial">
    <h3 class="title">
      <span>待调配 ({{ total }})</span>
      <hr />
    </h3>
    <div class="wrap" v-for="(item,i) in auditedList" :key="i">
      <div class="user">{{ item.patient_name || "无名氏" }}</div>
      <div class="date">
        开方时间:
        <span>{{ item.add_time }}</span>
      </div>
      <button @click="changeState(item)">查看</button>
    </div>
    <div class="pagination" v-if="showPage">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="total"
        :page-size="15"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
// 获取待审核的处方列表
import { getWaitingAuditList } from "@/request/personnel";

export default {
  data() {
    return {
      auditedList: [],
      total: 0,
      showPage: false // 是否显示分页条
    };
  },
  created() {
    this.getPageWaitingAuditList(1)
  },
  methods: {
    getPageWaitingAuditList(n) {
      // 获取当前页的信息
      getWaitingAuditList({
        token: this.$token,
        page: n
      }).then(res => {
        if(res.code !== 1) return; 
        this.total = res.data.total;
        this.showPage = res.data.last_page !== 1
        this.auditedList = res.data.data;
        if(this.auditedList.length == 0) {
          this.$message({
            type: "Warning",
            message: "当前没有待调配的处方单"
          })
        }
      });
    },
    handleCurrentChange(n) {
      this.getPageWaitingAuditList(n)
    },
    changeState(item) {
      this.$emit("changeState", 1, item.p_code);
    }
  }
};
</script>

<style lang="less" scoped>
.trial {
  width: 1200px;
  min-height: 600px;
  margin: 0 auto ;
  padding-bottom: 100px;
  position: relative;
}
.trial .title {
  font-size: 22px;
  font-weight: 400;
  margin-top: 50px;
}
hr {
  border: none;
  width: 1060px;
  height: 1px;
  display: inline-block;
  margin-left: 10px;
  margin-bottom: 5px;
  background: rgba(234, 234, 234, 1);
}

.trial .wrap {
  width: 1200px;
  height: 143px;
  font-size: 14px;
  margin-top: 30px;
  padding-bottom: 14px;
  background: #fbfbfb;
  border: 1px solid #f5f5f5;
  position: relative;
  button {
    position: absolute;
    width: 80px;
    height: 28px;
    border-radius: 8px;
    right: 30px;
    top: 60px;
    cursor: pointer;
  }
  .user {
    margin-top: 35px;
    margin-left: 25px;
  }
  .date {
    margin-top: 25px;
    margin-left: 25px;
  }
}
.trial .wrap div {
  margin-top: 8px;
  color: #656565;
}
.trial .wrap div.user {
  color: #71c0d6;
  font-size: 20px;
  margin-bottom: 10px;
}
.pagination /deep/ {
  .el-pagination {
    position: absolute;
    bottom: 30px;
    width: 100%;
    text-align: center;
  }
}
</style>